import React, { FC, useState } from 'react';
import styled from 'styled-components';

import BannerOne from '../BannerOne';
import { mobile, smMobile, tablet } from 'styles/breakpoints';
import PaymentSteps from 'pages/start/payments/components/PaymentSteps';
import Button from '../Button';
import CancelButton from '../CancelButton';
import { DynamicImage, Loader } from 'components';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';

interface ExpressShippingProps {}

const PaymentStepsStyled = styled(PaymentSteps)`
  display: flex;
  justify-content: center;
  padding: 1.5rem 1rem;
  width: 100%;
`;

const CancelButtonStyled = styled(CancelButton)`
  margin: 0.75rem 0 0 0;
`;

const BotTitleStyled = styled.p`
  max-width: 48.375rem;
  margin-bottom: 0.75rem;
  margin-top: 5.5rem;
  color: #000;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.25rem;
  @media ${tablet} {
    margin-top: 1rem;
    font-size: 1.25rem;
    line-height: 2rem;
  }
`;

const BotLabelStyled = styled.p`
  max-width: 48.375rem;
  margin-bottom: 2rem;
  color: #28293d;
  text-align: center;
  font-size: 1.125rem;
  @media ${tablet} {
    font-size: 1rem;
    line-height: 1.4375rem;
    padding: 0 1rem;
  }
`;

const Title3 = styled(BotTitleStyled)`
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.25rem;
  margin-top: 0rem;
  @media ${tablet} {
    font-size: 1.25rem;
    line-height: 2rem;
    padding: 0;
  }
`;

const Label3 = styled(BotLabelStyled)`
  @media ${tablet} {
    margin-bottom: 0.75rem;
    padding: 0;
  }
`;

const TopTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${tablet} {
    padding: 0 1rem;
  }
`;

const Headline = styled.p`
  color: #1c1c28;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.25rem;
  margin-bottom: 1rem;
  max-width: 48.375rem;
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 1.8125rem;
    margin-bottom: 0.5rem;
  }
`;

const Label = styled.p`
  color: #28293d;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 2.25rem;
  text-align: center;
  max-width: 30.5rem;
  @media ${tablet} {
    font-size: 1rem;
    line-height: 1.4375rem;
    margin-bottom: 1rem;
  }
`;

const BookContainer = styled.div`
  position: relative;
  max-width: 18.7rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const BookCover = styled(DynamicImage)`
  width: 11.1rem;
  height: 14.4rem;
  z-index: 2;
`;

const BabyName = styled.p<{ bookColor: string }>`
  color: ${({ bookColor }) => (bookColor === 'beige' ? '#C7546C' : '#fffde9')};
  font-family: 'Brandon Grotesque', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 2.02731rem;
  text-transform: uppercase;
  position: absolute;
  top: 2.7rem;
  right: 50%;
  transform: translateX(50%);
  z-index: 3;
  white-space: nowrap;
  @media ${mobile} {
    font-weight: 400;
  }
`;

const BottomNames = styled.p<{ bookColor: string; isLongText: boolean }>`
  color: ${({ bookColor }) => (bookColor === 'beige' ? '#C7546C' : '#fffde9')};
  font-size: 0.38rem;
  font-weight: 700;
  line-height: 0.4rem;
  text-transform: uppercase;
  position: absolute;
  bottom: ${({ isLongText }) => (isLongText ? '1.4rem' : '1.8rem')};
  right: 50%;
  transform: translateX(50%);
  white-space: nowrap;
  font-family: 'Brandon Grotesque', sans-serif;
  z-index: 3;
  text-align: center;
  @media ${mobile} {
    font-weight: 400;
  }
`;

const ImageStyled = styled(DynamicImage)`
  z-index: 1;
  position: absolute;
  top: -0.04494rem;
  width: 16.81675rem;
  height: 14.5rem;
`;

const BookContaineBottom = styled(BookContainer)`
  max-width: 20.5rem;
  @media ${tablet} {
    max-width: 10.25rem;
  }
`;

const BookCoverBottom = styled(BookCover)`
  width: 16.75rem;
  height: 22.5rem;
  @media ${mobile} {
    width: 8.375rem;
    height: 11.25rem;
  }
`;

const ImageStyledBottom = styled(ImageStyled)`
  width: 17rem;
  height: 22.3rem;
  transform: rotate(2.097deg);
  top: 0px;
  right: -30px;
  @media ${mobile} {
    width: 8.5rem;
    height: 11.2rem;
    right: -14px;
  }
`;

const BabyNameBottom = styled(BabyName)`
  font-size: 1.55rem;
  line-height: 3.07231rem;
  top: 4.4rem;
  @media ${tablet} {
    font-size: 0.78rem;
    line-height: 1.53613rem;
    top: 2.23rem;
  }
  @media ${mobile} {
    font-weight: 400;
  }
`;

const BottomNamesBottom = styled(BottomNames)<{ isLongText: boolean }>`
  font-size: 0.58rem;
  line-height: 0.75rem;
  bottom: ${({ isLongText }) => (isLongText ? '2.3rem' : '3rem')};
  @media ${tablet} {
    font-size: 0.34rem;
    line-height: 0.46675rem;
    bottom: ${({ isLongText }) => (isLongText ? '0.95rem' : '1.35rem')};
  }
  @media ${mobile} {
    font-weight: 400;
  }
`;

const BottomContainer = styled.div`
  background: #f0f0f0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 1rem;
  margin-top: 4rem;
  @media ${tablet} {
    margin-top: 1.5rem;
    padding: 1.5rem 1rem 3rem;
  }
`;

const EbookContainer = styled.div`
  display: flex;
  padding: 0.1295rem 0.51794rem;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -10px;
  border-radius: 0.27181rem;
  background: #ef71a0;
  z-index: 3;
  right: 50%;
  transform: translateX(50%);
  color: #faf1eb;
  text-align: center;
  font-size: 1.03588rem;
  font-weight: 800;
`;

const ExpressShipping: FC<ExpressShippingProps> = ({
  bannerOne,
  paymentSteps,
  headline,
  label,
  PlanSectionComponent,
  planSection,
  headline2,
  label2,
  imageSection,
  headline3,
  label3,
  button,
  cancelButton,
  userHasBoughtPaperbackBook,
  loading,
  handleUpgradeClick,
  localisedProduct,
  handleCancelClick,
}) => {
  const quizAnswers = useSelector((state: AppState) => state.user.quiz_answers);
  const [showName, setShowName] = useState<boolean>(false);

  const renderNames = () => {
    const { mom_name, dad_name, baby_name, parenting_situation } =
      quizAnswers || {};

    let namesString = '';

    switch (parenting_situation) {
      case 'mom':
        namesString = `${mom_name} & ${baby_name}`;
        break;
      case 'dad':
        namesString = `${dad_name} & ${baby_name}`;
        break;
      default:
        namesString = `${mom_name}, ${dad_name} & ${baby_name}`;
        break;
    }

    const isLongText = namesString.length > 35;

    if (
      (isLongText && parenting_situation === 'parents') ||
      (isLongText && parenting_situation.includes('two'))
    ) {
      namesString = namesString.replace(' & ', ' &<br/> ');
    }

    return { namesString, isLongText };
  };

  const { namesString, isLongText } = renderNames();

  const handleOnLoad = () => {
    setShowName(true);
  };

  return (
    <>
      {bannerOne && (
        <BannerOne
          {...bannerOne}
          boughtPaperback={userHasBoughtPaperbackBook}
        />
      )}

      <PaymentStepsStyled
        {...paymentSteps}
        hasOptionalStep={userHasBoughtPaperbackBook}
      />

      <TopTextContainer>
        <Headline>{headline}</Headline>
        <Label>{label}</Label>
      </TopTextContainer>
      <BookContainer>
        <BookCover
          alt="Baby book cover"
          src={`booksCovers/${quizAnswers?.color}_${quizAnswers?.icon}.png`}
          handleOnLoad={handleOnLoad}
        />

        <ImageStyled src="upsell/background.png" alt="" />
        {showName && (
          <>
            <BabyName bookColor={quizAnswers?.color}>
              {quizAnswers?.baby_name}
            </BabyName>
            <BottomNames
              bookColor={quizAnswers?.color}
              isLongText={isLongText}
              dangerouslySetInnerHTML={{ __html: namesString }}
            />
            <EbookContainer>ebook</EbookContainer>
          </>
        )}
      </BookContainer>
      <PlanSectionComponent {...planSection} />
      <BotTitleStyled>{headline2}</BotTitleStyled>
      <BotLabelStyled>{label2}</BotLabelStyled>
      <BookContaineBottom>
        <BookCoverBottom
          alt="Baby book cover"
          src={`booksCovers/${quizAnswers?.color}_${quizAnswers?.icon}.png`}
        />
        <ImageStyledBottom
          src="upsell/express-shipping/mockup-pages.png"
          alt=""
        />
        <BabyNameBottom bookColor={quizAnswers?.color}>
          {quizAnswers?.baby_name}
        </BabyNameBottom>
        <BottomNamesBottom
          bookColor={quizAnswers?.color}
          isLongText={isLongText}
          dangerouslySetInnerHTML={{ __html: namesString }}
        />
      </BookContaineBottom>
      <BottomContainer>
        <Title3>{headline3}</Title3>
        <Label3>{label3}</Label3>
        {loading ? (
          <Loader wrapperHeight={'1'} />
        ) : (
          <Button
            bgColor="primary"
            disabled={loading}
            onClick={handleUpgradeClick}
            {...button}
            secondaryText={`${button.secondaryText} ${localisedProduct?.currency}${localisedProduct?.finalPrice}`}
          >
            {button.text}
          </Button>
        )}
        <CancelButtonStyled
          disabled={loading}
          onCancelClick={handleCancelClick}
          noButtonTitle={cancelButton.noButtonTitle}
        />
      </BottomContainer>
    </>
  );
};

export default ExpressShipping;
